.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lang-icon {
  height: 50px;
}

.jconfirm .jconfirm-box {
  background: #242629 !important;
}
.jconfirm .jconfirm-box .jconfirm-buttons button.btn-default{
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}
.jconfirm.jconfirm-white .jconfirm-box .jconfirm-buttons button.btn-default, .jconfirm.jconfirm-light .jconfirm-box .jconfirm-buttons button.btn-default{
  color: #fff !important;
}

.offcanvas { 
  background-color:rgba(0,0,0,0.9) !important;
}
thead, tbody, tfoot, tr, td, th{
  font-size: 12px;
}